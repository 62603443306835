import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'softposv3-portal';
  isProduction = environment.production;

  onThemeChange = (theme: string) => {
    // get the selected theme's CSS file
    const themeCss = `./${theme}.css`;

    // remove any existing theme CSS files
    const links = this.document.head.querySelectorAll('link[rel="stylesheet"][data-app-theme]');
    links.forEach(link => link.remove());

    // add the new theme CSS file
    const linkEl = this.document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.setAttribute('href', themeCss);
    linkEl.setAttribute('data-app-theme', '');

    this.document.head.appendChild(linkEl);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private oauthService: OAuthService
  ) {}

  ngOnInit(): void {
    this.authService.getSSOConfig().subscribe((response) => {
      const { Issuer, ClientID } = response.Data;
      const AuthCodeFlowConfig: AuthConfig = {
        // Url of the Identity Provider
        issuer: Issuer,

        // URL of the SPA to redirect the user to after login
        redirectUri: window.location.origin +'/auth/login',

        // The SPA's id. The SPA is registerd with this id at the auth-server
        // clientId: 'server.code',
        clientId: ClientID,

        responseType: 'code',

        // set the scope for the permissions the client should request
        // The first four are defined by OIDC.
        // Important: Request offline_access to get a refresh token
        // The api scope is a usecase specific one
        scope: 'openid profile email offline_access',

        showDebugInformation: true,
        disablePKCE:true
      };
      this.oauthService.configure(AuthCodeFlowConfig);
      this.oauthService.loadDiscoveryDocument();
    });
  }
}
