import { FromHex, FromUtf8, ToHex } from './buffer';

const algorithm = 'AES-GCM';

const GetAESKey = (keyHex: string) => window.crypto.subtle.importKey(
  'raw',
  FromHex(keyHex),
  algorithm,
  false,
  ["encrypt", "decrypt"],
);

export const AESGCMEncrypt = async (data: string, keyHex: string, iv: string) => {
  const key = await GetAESKey(keyHex);
  return window.crypto.subtle.encrypt(
    {
      name: algorithm,
      iv: FromHex(iv),
    },
    key,
    FromUtf8(data),
  );
}

export const AESGCMDecrypt = async (dataHex: string, keyHex: string, iv?: string) => {
  const key = await GetAESKey(keyHex);
  return window.crypto.subtle.decrypt(
    {
      name: algorithm,
      iv: iv ? FromHex(iv) : new Uint8Array(12).buffer,
    },
    key,
    FromHex(dataHex),
  );
}

export const SHA512 = async (data: string) => ToHex(await window.crypto.subtle.digest("SHA-512", FromUtf8(data)));
