import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { IsAuthenticated, IsUnauthenticated } from '@app/auth/auth.guard';
import { AuthInterceptor } from '@app/auth/auth.interceptor';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { initializeLanguage, initializeSupportedLocales } from './app.initializers';
import { ErrorhandlerService } from './shared/errorhandler.service';
import { OAuthModule } from 'angular-oauth2-oidc';

// only valid in non-production version
const sampleRoutes = environment.production ? [] : [
  { path: 'sample', loadComponent: () => import('./modules/sample/sample.component').then(mod => mod.SampleComponent) },
]

const routes: Routes = [
  { path: 'auth', canMatch: [IsUnauthenticated], loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'portal', canMatch: [IsAuthenticated], loadChildren: () => import('./modules/portal/portal.module').then(m => m.PortalModule) },
  ...sampleRoutes,
  { path: 'error/forbidden', loadComponent: () => import('./modules/error/forbidden/forbidden.component').then(mod => mod.ForbiddenComponent) },
  { path: 'error/not-found', loadComponent: () => import('./modules/error/not-found/not-found.component').then(mod => mod.NotFoundComponent) },
  { path: 'error/maintenance', loadComponent: () => import('./modules/error/maintenance/maintenance.component').then(mod => mod.MaintenanceComponent) },
  { path: 'error/internal-server-error', loadComponent: () => import('./modules/error/internal-server-error/internal-server-error.component').then(mod => mod.InternalServerErrorComponent) },
  { path: '**', redirectTo: 'portal', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    NgbModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [AuthService] },
    { provide: LOCALE_ID, useFactory: initializeSupportedLocales },
    { provide: APP_INITIALIZER, useFactory: () => initializeLanguage, multi: true },
    { provide: ErrorHandler, useClass: ErrorhandlerService },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule { }
