{
  "locale": "vi",
  "translations": {
    "ngb.alert.close": "Close",
    "ngb.timepicker.HH": "HH",
    "ngb.toast.close-aria": "Close",
    "ngb.pagination.first": "««",
    "ngb.datepicker.select-month": "Select month",
    "ngb.datepicker.previous-month": "Previous month",
    "ngb.progressbar.value": "{$INTERPOLATION}",
    "ngb.carousel.slide-number": " Slide {$INTERPOLATION} of {$INTERPOLATION_1} ",
    "ngb.timepicker.hours": "Hours",
    "ngb.pagination.previous": "«",
    "ngb.carousel.previous": "Previous",
    "ngb.timepicker.MM": "MM",
    "ngb.pagination.next": "»",
    "ngb.datepicker.select-year": "Select year",
    "ngb.datepicker.next-month": "Next month",
    "ngb.carousel.next": "Next",
    "ngb.timepicker.minutes": "Minutes",
    "ngb.pagination.last": "»»",
    "ngb.timepicker.increment-hours": "Increment hours",
    "ngb.pagination.first-aria": "First",
    "ngb.pagination.previous-aria": "Previous",
    "ngb.timepicker.decrement-hours": "Decrement hours",
    "ngb.pagination.next-aria": "Next",
    "ngb.timepicker.increment-minutes": "Increment minutes",
    "ngb.pagination.last-aria": "Last",
    "ngb.timepicker.decrement-minutes": "Decrement minutes",
    "ngb.timepicker.SS": "SS",
    "ngb.timepicker.seconds": "Seconds",
    "ngb.timepicker.increment-seconds": "Increment seconds",
    "ngb.timepicker.decrement-seconds": "Decrement seconds",
    "ngb.timepicker.PM": "{$INTERPOLATION}",
    "ngb.timepicker.AM": "{$INTERPOLATION}",
    "Icon.Activate": "<i class='fa fa-check'></i>",
    "Icon.Add": "<i class='fa fa-plus'></i>",
    "Icon.Append": "<i class='fa fa-plus'></i>",
    "Icon.Apply": "<i class='fa fa-check'></i>",
    "Icon.Approve": "<i class='fa fa-check'></i>",
    "Icon.BalanceInquiry": "<i class='fa fa-search-plus'></i>",
    "Icon.Calculate": "<i class='fa fa-calculator'></i>",
    "Icon.Callback": "<i class='fa fa-reply'></i>",
    "Icon.Cancel": "<i class='fa fa-close'></i>",
    "Icon.CapturePhoto": "<i class='fa fa-camera'></i>",
    "Icon.Clean": "<i class='fa fa-eraser'></i>",
    "Icon.Clear": "<i class='fa fa-eraser'></i>",
    "Icon.Close": "<i class='fa fa-close'></i>",
    "Icon.Collapse": " <i class='fa fa-compress'></i>",
    "Icon.Confirm": "<i class='fa fa-check'></i>",
    "Icon.Copy": "<i class='fa fa-files-o'></i>",
    "Icon.Create": "<i class='fa fa-save'></i>",
    "Icon.CreateRequest": "<i class='fa fa-plus'></i>",
    "Icon.Decline": "<i class='fa fa-close'></i>",
    "Icon.Delete": "<i class='fa fa-trash-o'></i>",
    "Icon.Disable": "<i class='fa fa-close'></i>",
    "Icon.Download": "<i class='fa fa-download'></i>",
    "Icon.Duplicate": "<i class='fa fa-files-o'></i>",
    "Icon.Enable": "<i class='fa fa-check'></i>",
    "Icon.Exchange": " <i class='fa fa-exchange'></i>",
    "Icon.Exit": "<i class='fa fa-sign-out'></i>",
    "Icon.Export": "<i class='fa fa-file-o'></i>",
    "Icon.Filter": "<i class='fa fa-filter'></i>",
    "Icon.Import": "<i class='fa fa-upload'></i>",
    "Icon.Inquiry": "<i class='fa fa-search-plus'></i>",
    "Icon.Insert": "<i class='fa fa-save'></i>",
    "Icon.Load": "<i class='fa fa-eye'></i>",
    "Icon.Lock": "<i class='fa fa-lock'></i>",
    "Icon.OK": "<i class='fa fa-check'></i>",
    "Icon.Overwrite": "<i class='fa fa-recycle'></i>",
    "Icon.Payment": "<i class='fa fa-credit-card'></i>",
    "Icon.Print": "<i class='fa fa-print'></i>",
    "Icon.Refresh": "<i class='fa fa-refresh'></i>",
    "Icon.Register": "<i class='fa fa-plus'></i>",
    "Icon.Reload": "<i class='fa fa-recycle'></i>",
    "Icon.Remove": "<i class='fa fa-trash-o'></i>",
    "Icon.Resend": "<i class='fa fa-send'></i>",
    "Icon.Reset": "<i class='fa fa-eraser'></i>",
    "Icon.ResetData": "<i class='fa fa-eraser'></i>",
    "Icon.Restore": "<i class='fa fa-recycle'></i>",
    "Icon.Return": "<i class='fa fa-reply'></i>",
    "Icon.Reversal": "<i class='fa fa-close'></i>",
    "Icon.Save": "<i class='fa fa-save'></i>",
    "Icon.Search": "<i class='fa fa-search-plus'></i>",
    "Icon.Send": "<i class='fa fa-send'></i>",
    "Icon.SendEmail": "<i class='fa fa-envelope'></i>",
    "Icon.SendRequest": "<i class='fa fa-send'></i>",
    "Icon.SendSMS": "<i class='fa fa-mobile fa-lg'></i>",
    "Icon.Settle": "<i class='fa fa-calculator'></i>",
    "Icon.SignIn": "<i class='fa fa-sign-in'></i>",
    "Icon.SignInUp": " <i class='fa fa-user'></i>",
    "Icon.SignOut": "<i class='fa fa-sign-out'></i>",
    "Icon.Sort": "<i class='fa fa-sort'></i>",
    "Icon.Submit": "<i class='fa fa-send'></i>",
    "Icon.Sync": "<i class='fa fa-exchange'></i>",
    "Icon.TopUp": "<i class='fa fa-credit-card'></i>",
    "Icon.Transfer": "<i class='fa fa-credit-card'></i>",
    "Icon.Unlock": "<i class='fa fa-unlock'></i>",
    "Icon.Update": "<i class='fa fa-save'></i>",
    "Icon.Upload": "<i class='fa fa-upload'></i>",
    "Icon.Validate": "<i class='fa fa-exclamation-triangle'></i>",
    "Icon.View": "<i class='fa fa-eye'></i>",
    "Icon.ViewDetail": "<i class='fa fa-search-plus'></i>",
    "Icon.ViewHistory": "<i class='fa fa-history'></i>",
    "Account_Bank": "Ngân hàng",
    "Account_Branch": "Chi nhánh",
    "Account_Holder": "Chủ tài khoản",
    "Account": "Tài khoản",
    "AccountHolder": "Chủ tài khoản",
    "AccountID": "Mã tài khoản",
    "AccountingAmount": "Số tiền hạch toán",
    "AccountLimit": "Hạn mức tài khoản",
    "AccountMerchant": "Tài khoản Merchant",
    "AccountMerchantID": "Mã tài khoản Merchant",
    "AccountNo": "Số tài khoản thụ hưởng",
    "AccountNumber": "Số tài khoản",
    "AccountOwner": "Chủ tài khoản",
    "AccountType": "Loại tài khoản",
    "AccountTypeCode": "Mã loại tài khoản",
    "Action": "Thao tác",
    "ActionDateTime": "Ngày thực hiện",
    "ActionMade": "Hành động",
    "ActionName": "Tên hành động",
    "ActionUserID": "Người thực hiện",
    "Activate": "Kích hoạt",
    "Active": "Hoạt động",
    "ActionType": "Loại hành động",
    "Add": "Thêm mới",
    "Address": "Địa chỉ",
    "AddressLine01": "Địa chỉ dòng 01",
    "AddressLine02": "Địa chỉ dòng 02",
    "AddressLine03": "Địa chỉ dòng 03",
    "AddUser": "Thêm Mới Người Dùng",
    "Administration": "Quản Trị",
    "AID": "AID",
    "All": "Tất cả",
    "AllowCancel": "Cho phép hủy",
    "AllowTimes": "Số lần áp dụng",
    "Alternative01AddressLine01": "Địa chỉ dòng 01",
    "Alternative01AddressLine02": "Địa chỉ dòng 02",
    "Alternative01AddressLine03": "Địa chỉ dòng 03",
    "Alternative01CityCode": "Quận/Huyện",
    "Alternative01CountryCode": "Quốc gia",
    "Alternative01PostalCode": "Mã bưu chính",
    "Alternative01StateCode": "Thành phố/Tỉnh",
    "Alternative02AddressLine01": "Địa chỉ dòng 01",
    "Alternative02AddressLine02": "Địa chỉ dòng 02",
    "Alternative02AddressLine03": "Địa chỉ dòng 03",
    "Alternative02CityCode": "Quận/Huyện",
    "Alternative02CountryCode": "Quốc gia",
    "Alternative02PostalCode": "Mã bưu chính",
    "Alternative02StateCode": "Thành phố/Tỉnh",
    "AmountApply": "Số tiền áp dụng",
    "AmountTxnDebt": "Tổng số tiền Công nợ",
    "AmountTxnSpos": "Tổng số tiền SOFTPOS",
    "Announce": "Thông báo",
    "APP": "APP",
    "Append": "Thêm dữ liệu",
    "Apply": "Áp dụng",
    "Approve": "Duyệt",
    "ApproveAll": "Duyệt tất cả",
    "ApproveCode": "Mã chuẩn chi",
    "ApproveCodeRequired": "Vui lòng nhập Mã chuẩn chi",
    "Approved": "Đã duyệt (Thành công)",
    "ApprovedDescription": "Lý do",
    "ApproveInstallment": "Xác nhận yêu cầu",
    "ApproveReconciliationDifferenceConfirm": "Bạn có chắc chắn muốn duyệt bản ghi này?",
    "AppVersion": "Phiên bản App",
    "AssemblyName": "Assembly Name",
    "AuthenticationType": "Loại hình xác thực",
    "AuthenticationTypeCode": "Mã loại xác thực",
    "Authorised": "Tình trạng",
    "AutoSettlementDetail": "Thông Tin Kết Toán Tự Động",
    "AutoSettlementManagement": "Quản Lý Kết Toán Tự Động",
    "AvailableBalance": "Số tiền hiện hữu",
    "AvgSale": "Doanh thu trung bình tháng",
    "AvgTransaction": "Giá trị trung bình mỗi giao dịch",
    "BadRequest": "Dữ liệu không hợp lệ",
    "BalanceInquiry": "Tra cứu số dư",
    "Bank": "Ngân hàng",
    "BankAcceptCancel": "Ngân hàng hủy thành công",
    "BankAccount": "Tài khoản ngân hàng",
    "BankAccountHolderName01": "Tên chủ tài khoản 01",
    "BankAccountHolderName02": "Tên chủ tài khoản 02",
    "BankAccountHolderName03": "Tên chủ tài khoản 03",
    "BankAccountNumber01": "Số tài khoản 01",
    "BankAccountNumber02": "Số tài khoản 02",
    "BankAccountNumber03": "Số tài khoản 03",
    "BankApprove": "Ngân hàng chuyển trả góp thành công",
    "BankBranchName01": "Tên chi nhánh 01",
    "BankBranchName02": "Tên chi nhánh 02",
    "BankBranchName03": "Tên chi nhánh 03",
    "BankCode": "Mã ngân hàng",
    "BankCode01": "Tên ngân hàng 01",
    "BankCode02": "Tên ngân hàng 02",
    "BankCode03": "Tên ngân hàng 03",
    "BankDeny": "Ngân hàng từ chối chuyển yêu cầu",
    "BankID": "Bank ID",
    "BankMDRID": "Mã MDR",
    "BankName": "Tên ngân hàng",
    "BankRoute": "NH xử lý giao dịch",
    "BankTransfer": "Chuyển bank",
    "BasicCardNumber": "Số thẻ chính",
    "BatchNo": "Số batch",
    "Before": "Trước đây",
    "BeforeInsert": "Before Insert",
    "BeforeUpdate": "Before Update",
    "BeneficiaryBank": "Ngân hàng thụ hưởng",
    "BeneficiaryBranch": "Chi nhánh thụ hưởng",
    "BeneficiaryMethod": "Phương thức nhận tiền",
    "BillAddress": "Địa chỉ trên hóa đơn",
    "BillingCycle": "Chu kỳ",
    "BillNumber": "Mã đơn hàng",
    "BillType": "Loại hóa đơn",
    "BINGroupCode": "Nhóm mã BIN",
    "BirthDate": "Ngày sinh",
    "Branch": "Chi nhánh",
    "BranchAmount": "Số lượng chi nhánh",
    "BranchApply": "Chi nhánh áp dụng",
    "BranchCode": "Mã chi nhánh",
    "BranchID": "Mã chi nhánh",
    "BranchInformation": "Thông Tin Chi Nhánh",
    "BranchLevel": "Cấp chi nhánh",
    "BranchManagement": "Quản Lý Chi Nhánh",
    "BranchManager": "Chi nhánh quản lý",
    "BranchName": "Chi nhánh",
    "BranchParent": "Trực thuộc chi nhánh",
    "BranchPath": "Sơ đồ nhánh",
    "BranchShortName": "Branch Short Name",
    "BrandName": "Tên thương hiệu",
    "BusinessLicenseType": "Loại hình doanh nghiệp",
    "BusinessType": "Loại hình doanh nghiệp",
    "Button.RequestUpdatePassword": "Gửi yêu cầu đổi mật khẩu",
    "CacheDetails": "Chi Tiết Cache",
    "CacheID": "Cache ID",
    "CacheKey": "Khóa cache",
    "CacheManagement": "Quản lý cache",
    "CacheName": "Cache Name",
    "CacheType": "Loại cache",
    "Cache": "Cache",
    "Calculate": "Tính toán",
    "Callback": "Lấy lại",
    "Cancel.1": "Hủy bỏ",
    "Cancel": "Hủy",
    "CancelRequest": "yêu cầu hủy",
    "CannotUpdateRecordProcessing": "Không thể cập nhật do vẫn có bản ghi chưa xử lý xong",
    "CapturePhoto": "Chụp ảnh",
    "Card": "Thẻ",
    "CardAccount": "Số tài khoản thẻ",
    "CardBIN": "Card BIN",
    "CardBINGroupCode": "Nhóm BIN thẻ",
    "CardBrand": "Loại thẻ",
    "CardHolderName": "Tên chủ thẻ",
    "CardLimit": "Hạn mức thẻ",
    "CardNumber": "Số thẻ",
    "CardScheme": "Loại thẻ",
    "CardStatus": "Trạng thái thẻ",
    "CardType": "Loại thẻ",
    "CardUniqueID": "Mã thẻ chính",
    "CashBackAmount": "Số tiền hoàn lại",
    "CashBackDate": "Ngày hoàn tiền",
    "CashID": "Mã tiền thưởng",
    "CashName": "Tên gợi nhớ",
    "CashTypeCode": "Loại hình khuyến mãi",
    "Category": "Danh mục",
    "CategoryManagement": "Quản lý danh mục",
    "CCEmail": "CC Email",
    "ChangePassword": "Đổi Mật Khẩu",
    "Channel": "Kênh",
    "ChannelID": "Kênh",
    "ChartType": "Loại biểu đồ",
    "CIFNo": "Mã khách hàng",
    "City": "Thành phố/ Tỉnh",
    "CityCode": "Quận/Huyện",
    "ClassName": "Class Name",
    "Clean": "Làm mới",
    "Clear": "Xóa",
    "Close": "Đóng",
    "Collapse": "Thu nhỏ",
    "ComeBack": "Quay lại",
    "Comment": "Ghi chú",
    "CompanyName": "Tên doanh nghiệp",
    "CompanyPhone": "Số điện thoại công ty",
    "Complete": "Hoàn tất",
    "Confidence": "Điểm tin cậy",
    "Confirm": "Xác nhận",
    "ConfirmNewPassword": "Nhập lại mật khẩu mới",
    "ConfirmPassword": "Nhập lại mật khẩu",
    "ConfirmPasswordMismatch": "Mật khẩu xác nhận không khớp",
    "ConnectionName": "Connection Name",
    "ConsultantName": "Nhân viên tư vấn",
    "ContainUnmatchRecord": "Tồn tại dữ liệu chưa khớp",
    "Content": "Nội dung",
    "Contract": "Hợp đồng",
    "Copy": "Sao chép",
    "Correct": "Khớp đúng",
    "CountryCode": "Quốc gia",
    "CountTrxn": "Số lượng giao dịch",
    "Create": "Tạo",
    "CreateDate": "Ngày mở",
    "CreateRefund": "Tạo hoàn",
    "CreateRequest": "Tạo yêu cầu",
    "Criteria": "Tiêu chí bậc",
    "CurrencyCode": "Mã Tiền tệ",
    "CurrencyRate": "Tỉ giá",
    "Current": "Hiện tại",
    "CustomDataSource": "Custom Data Source",
    "Customer": "Khách hàng",
    "CustomerID": "CMND",
    "CustomerName": "Tên khách hàng",
    "CustomerPaymentFeeCard": "% KH chịu phí trả góp",
    "CustomerPaymentFeeInstallment": "% KH chịu phí thanh toán thẻ",
    "CustomerType": "Nhóm khách hàng",
    "CVMLimitJCB": "CVM Limit JCB",
    "CVMLimitMaster": "CVM Limit Master",
    "CVMLimitMC": "CVM Limit MC PAN",
    "CVMLimitVCCS": "CVM Limit VCCS",
    "CVMLimitVisa": "CVM Limit Visa",
    "DailyLimit": "Hạn mức trong ngày",
    "DashboardNHPH": "NHPH",
    "DataAlreadyExists": "Dữ liệu đã tồn tại",
    "DatabaseName": "Database Name",
    "DataSource": "Data Source",
    "DataType": "Loại thông tin",
    "DateEnd": "Ngày kết thúc",
    "DateFrom": "Từ ngày",
    "DateIssue": "Ngày Cấp",
    "DateProcess": "Ngày xử lý",
    "DateRange": "Từ ngày - đến ngày",
    "DateStart": "Ngày bắt đầu",
    "DateTimeAction": "Thời gian thực hiện",
    "DateTimeActivate": "Ngày kích hoạt",
    "DateTimeApprove": "Ngày duyệt/từ chối/hủy",
    "DateTimeCancel": "Ngày hủy",
    "DateTimeCreate": "Ngày tạo",
    "DateTimeCreated": "Ngày giờ tạo",
    "DateTimeEffect": "Ngày bắt đầu hiệu lực",
    "DateTimeExpire": "Ngày hết hạn",
    "DateTimeIssue": "Ngày phát hành",
    "DateTimeLock": "Ngày khóa",
    "DateTimeModify": "Ngày cập nhật",
    "DateTimeQuantity": "Theo thời gian",
    "DateTimeReprint": "Ngày giờ in lại",
    "DateTimeSend": "Thời gian upload",
    "DateTo": "Đến ngày",
    "DateType": "Tìm theo ngày",
    "DateTypeSale": "Ngày giờ giao dịch",
    "DateTypeVoid": "Ngày giờ hủy",
    "DBIssueBank": "NHPH",
    "DBMerchant": "Merchant",
    "DBTerminal": "Terminal",
    "DebitAccount": "Số tài khoản công nợ",
    "DebitAccountName": "Tên tài khoản công nợ",
    "DebtAcc": "Số tài khoản công nợ",
    "DebtAmount": "Số tiền hạch toán",
    "DebtDateTime": "Ngày giờ hạch toán khởi tạo",
    "DebtInformation": "Thông tin công nợ",
    "DebtPlanningID": "Mã hạch toán công nợ",
    "DebtReconciliation": "Đối soát công nợ",
    "Decision": "Decision",
    "DecisionFinal": "DecisionFinal",
    "Decline": "Từ chối",
    "DeclineRefundConfirm": "Bạn chắc chắn muốn từ chối duyệt giao dịch?",
    "DefaultValue": "Default Value",
    "Delete": "Xóa",
    "DeleteAll": "Xóa hết",
    "DeleteConfirm": "Bạn có chắc chắn xóa dữ liệu này?",
    "DeleteDate": "Ngày đóng",
    "DenyInstallment": "Hủy yêu cầu",
    "Description": "Ghi chú",
    "DestinationAccount": "Tài khoản đích",
    "DestinationAccountNumber": "Tài khoản đích",
    "DestinationBankCode": "Ngân hàng nhận",
    "DestinationBranchCode": "Chi nhánh nhận",
    "Detail": "Chi tiết",
    "DevelopmentUnit": "Đơn vị phát triển",
    "DevelopmentUnitAdd": "Thêm đơn vị phát triển",
    "DevelopmentUnitDetail": "Chi tiết đơn vị phát triển",
    "DevelopmentUnitInfo": "Thông tin ĐVPT",
    "Device": "Thiết bị",
    "DeviceApprove": "Duyệt thiết bị",
    "DeviceCategory": "Chủng loại thiết bị",
    "DeviceCode": "Mã Thiết Bị",
    "DeviceImport": "Import thiết bị",
    "DeviceSim": "Sim thiết bị",
    "DeviceStatus": "Trạng thái enroll thiết bị",
    "DevUnitCode": "Mã ÐVPT",
    "DevUnitName": "Tên ÐVPT",
    "Disable": "Vô hiệu",
    "Disabled": "Vô hiệu",
    "DisplayName": "Tên hiển thị",
    "Download": "Tải về",
    "DownloadFile": "Tải file",
    "DownloadFileSample": "Tải file mẫu",
    "DownloadFileCancel": "Tải file yêu cầu hủy",
    "DownloadFileNew": "Tải file yêu cầu trả góp",
    "DUPLICATE OR VALIDATE NOT SUCCESS": "Trùng dữ liệu hoặc dữ liệu không hợp lệ",
    "Duplicate": "Nhân bản",
    "DuplicateDataImport": "Trùng dữ liệu",
    "DuplicateMCC": "MCC, BankCode và thời gian hiệu lực này đã được khai báo MDR. Duplicate data.",
    "DuplicateMCCAndEffectDate": "MCC, BankCode và thời gian hiệu lực này đã được khai báo MDR. Duplicate data.",
    "DuplicateMID": "MID, BankCode và thời gian hiệu lực này đã được khai báo MDR. Duplicate data.",
    "DuplicateMIDAndEffectDate": "Không đúng định dạng",
    "Effect": "Hiệu lực",
    "EffectDate": "Ngày hiệu lực",
    "EffectDateGreaterCurrentDate": "Ngày hiệu lực phải lớn hơn hoặc bằng ngày hiện tại",
    "EffectDateGreaterCurrentMonth": "Ngày hiệu lực phải lớn hơn hoặc bằng tháng hiện tại",
    "EffectDateInvalid": "Ngày hiệu lực không hợp lệ",
    "EffectDateInvalid1": "Ngày hiệu lực không hợp lệ. Ngày hiệu lực không được nhỏ hơn ngày hiệu lực đã khai báo.",
    "EFFECTED DATE VALIDATE NOT SUCCESS": "Ngày hiệu lực không hợp lệ",
    "Effective": "Đang hiệu lực",
    "EffectMonth": "Tháng hiệu lực",
    "EffectTime": "Thời gian hiệu lực",
    "Email": "Email",
    "EmailBank": "Email",
    "EmailLogin": "Email đăng nhập",
    "EmbossingName": "Tên trên thẻ",
    "EmptyTableMessage": "Không có dữ liệu",
    "Enable": "Kích hoạt",
    "EndDateTime": "Thời gian kết thúc",
    "EnglishName": "English Name",
    "EnrollDate": "Ngày đăng ký",
    "EnterNewPassword": "Nhập mật khẩu mới",
    "EnterOldPassword": "Nhập mật khẩu cũ",
    "Enum.AccountType.BC": "Tài khoản thu",
    "Enum.AccountType.BE": "Tài khoản chi",
    "Enum.AccountType.MA": "Tài khoản Master",
    "Enum.AccountType.MI": "Tài khoản Issuer",
    "Enum.AccountType.PC": "Tài khoản cá nhân",
    "Enum.AccountType.PL": "Tài khoản điểm thưởng",
    "Enum.BillType.Electricity": "Điện",
    "Enum.BillType.Internet": "Internet",
    "Enum.BillType.Water": "Nước",
    "Enum.BusinessLicenseType.BizHouseholdCert": "Hộ gia đình",
    "Enum.BusinessLicenseType.EnterpriseCert": "Công ty",
    "Enum.BusinessLicenseType.PersonalCert": "Cá nhân",
    "Enum.CardRequestStatus.0": "Mới tạo",
    "Enum.CardRequestStatus.1": "Đã duyệt",
    "Enum.CardRequestStatus.2": "Bị từ chối",
    "Enum.CardStatus.": "Chưa kích hoạt",
    "Enum.CardStatus.A": "Đã kích hoạt",
    "Enum.CardStatus.C": "Đã hủy",
    "Enum.CardStatus.E": "Đã hết hạn",
    "Enum.CardStatus.L": "Đang tạm khóa",
    "Enum.CardType.C": "Thẻ tín dụng",
    "Enum.CardType.D": "Thẻ ghi nợ",
    "Enum.CardType.P": "Thẻ trả trước",
    "Enum.CashBackStatus.0": "Mới tạo",
    "Enum.CashBackStatus.1": "Đã duyệt",
    "Enum.CashBackStatus.2": "Bị từ chối",
    "Enum.CashType.CASH_BACK": "Hoàn tiền",
    "Enum.CashType.DISCOUNT": "Giảm giá",
    "Enum.CashType.TOP_UP": "Tặng tiền",
    "Enum.CustomerPhotoEnum.IDENTITY_BACK": "CMND (Sau)",
    "Enum.CustomerPhotoEnum.IDENTITY_FRONT": "CMND (Trước)",
    "Enum.CustomerPhotoEnum.IDENTITY": "CMND",
    "Enum.CustomerPhotoEnum.SELFIE": "Chân dung",
    "Enum.CustomerType.C": "Doanh nghiệp",
    "Enum.CustomerType.P": "Cá nhân",
    "Enum.FundTransferStatus.0": "Mới tạo",
    "Enum.FundTransferStatus.1": "Đã duyệt",
    "Enum.FundTransferStatus.2": "Bị từ chối",
    "Enum.Gender.F": "Nữ",
    "Enum.Gender.M": "Nam",
    "Enum.IDType.CC": "CCCD",
    "Enum.IDType.IC": "CMND",
    "Enum.IDType.Passport": "Hộ chiếu",
    "Enum.InstallmentStatus.0": "Khởi tạo",
    "Enum.InstallmentStatus.1": "Chờ xử lý",
    "Enum.InstallmentStatus.2": "Đang xử lý",
    "Enum.InstallmentStatus.3": "Đang xử lý hủy",
    "Enum.InstallmentStatus.4": "Chờ xử lý hủy",
    "Enum.InstallmentStatus.5": "Hủy",
    "Enum.InstallmentStatus.6": "Hoàn tất",
    "Enum.InstallmentStatus.7": "Từ chối",
    "Enum.InstallmentStatus.8": "Chờ tạo file",
    "Enum.RefundStatus.1": "Tạo hoàn",
    "Enum.RefundStatus.2": "Chờ duyệt hoàn",
    "Enum.RefundStatus.3": "Duyệt hoàn",
    "Enum.RefundStatus.4": "Từ chối hoàn",
    "Enum.RefundStatus.5": "Chờ duyệt không đủ số dư",
    "Enum.ResponseCode.0": "Đang xử lý",
    "Enum.ResponseCode.200": "Thành công",
    "Enum.ResponseCode.400": "Dữ liệu không hợp lệ",
    "Enum.ResponseCode.500": "Thất bại",
    "Enum.ResponseCode.504": "Quá thời gian",
    "Enum.TransactionType.ACE": "Quy đổi tiền tệ",
    "Enum.TransactionType.AFT": "Chuyển khoản",
    "Enum.TransactionType.AIFT": "Chuyển khoản liên ngân hàng",
    "Enum.TransactionType.CCB": "Hoàn tiền",
    "Enum.TransactionType.CEP": "Thanh toán Ecommerce",
    "Enum.TransactionType.CLR": "Điểm tích lũy",
    "Enum.TransactionType.CPP": "Thanh toán POS",
    "Enum.TransactionType.CTU": "Nạp tiền",
    "Enum.TransactionType.CTUB": "Nạp tiền (Tặng thêm)",
    "EReceipt": "Hóa Đơn Điện Tử",
    "EReceiptDetail": "Chi Tiết Hóa Đơn Điện Tử",
    "EReceiptSale": "Hoá đơn sale",
    "EReceiptSendIPNConfirm": "Hóa đơn sẽ được gửi đến hệ thống IPN. Bạn có chắc chắn bạn muốn tiếp tục?",
    "EReceiptVOID": "Hoá đơn Void",
    "Error": "Lỗi",
    "ErrorDetail": "Chi tiết lỗi",
    "ERROR_UPDATED": "Thao tác không thành công, do Hoàn tiền đã được chuyển sang trạng thái khác! Vui lòng kiểm tra lại",
    "ERROR_SYSTEM": "Lỗi hệ thống",
    "EvaluationRecord": "Hồ sơ thẩm định",
    "EWallet": "Ví điện tử",
    "Exchange": "Quy đổi",
    "Exit": "Thoát",
    "Expired": "Hết hạn",
    "ExpiredPasswordTitle": "Mật khẩu đã hết hạn vào {$INTERPOLATION}, bạn vui lòng cập nhật mật khẩu để tiếp tục sử dụng cho SoftPOS Portal.",
    "ExpirePasswordDateTime": "Thời gian mật khẩu hết hạn: {$INTERPOLATION}",
    "Export": "Xuất dữ liệu",
    "ExportCsv": "Xuất Csv",
    "ExportExcel": "Xuất Excel",
    "ExportMerchantInfo": "Export thông tin merchant",
    "ExportZip": "Xuất Zip",
    "F22POSEntryMode": "Phương thức giao dịch",
    "F38AuthorizationResponse": "Mã số chuẩn chi",
    "Fail": "Đã duyệt (Thất bại)",
    "Failed": "Thất bại",
    "Fee": "Phí",
    "FeeCode": "Mã phí",
    "FeeCommon": "Phí thông thường",
    "FeeCurrencyCode": "Mã tiền tệ (Phí)",
    "FeeDifference": "Chênh lệch phí",
    "FeeMode": "Hình thức phí",
    "FeeMDRMerchantMO": "Phí MDR đại lý MO",
    "FeeMDRMerchantMODetail": "Chi tiết phí MDR đại lý MO",
    "FeeProgressive": "Phí bậc thang",
    "FeePercent": "Phí phần trăm (%)",
    "FeeType": "Loại phí",
    "Female": "Nữ",
    "FieldGroup": "Field Group",
    "FieldID": "Field ID",
    "FieldLabel": "Field Label",
    "FieldName": "Tên File",
    "FieldText": "Field Text",
    "FieldValue": "Field Value",
    "FileCreateDate": "Ngày tạo file",
    "FileName": "Tên File",
    "FileNotSend": "Chưa gửi",
    "FileProcessingStatus": "Trạng thái xử lý file",
    "FileSent": "Đã Gửi",
    "FileSentDate": "Ngày gửi file",
    "FileStatus": "Trạng thái file",
    "Filter": "Lọc dữ liệu",
    "FirstName": "Họ",
    "Fixed": "Cố định",
    "FixedCharge": "Phí cố định (VNĐ)",
    "FlagDescription": "FlagDescription",
    "FlagName": "FlagName",
    "Footer": "Footer",
    "ForgotPassword": "Quên mật khẩu",
    "ForgotPasswordNotification": " Trong trường hợp bạn quên mật khẩu, chúng tôi sẽ gửi tới hộp thư mà bạn đăng ký một email dùng để đặt lại mật khẩu. Vui lòng nhấp vào liên kết trong email và làm theo hướng dẫn để đặt lại một khẩu khẩu mới.",
    "FromAddress": "Người gửi",
    "FullName": "Tên đầy đủ",
    "FullRefund": "Hoàn toàn phần",
    "FunctionName": "Function Name",
    "FunctionalName": "Tên chức năng",
    "FundTransferRequestID": "Mã yêu cầu chuyển khoản",
    "Gender": "Giới tính",
    "GenFile": "Tạo file",
    "HAD_INSTALLMENT": "Giao dịch trả góp đã phát sinh",
    "HardwareInfo": "Thông tin phần cứng",
    "HasAccount": "Has Account",
    "HaveEReceipt": "Hoá đơn Settle",
    "Header": "Header",
    "HeadOffice": "Địa chỉ trụ sở chính",
    "HeadOfficeAddress": "Địa chỉ trụ sở chính",
    "Help.Customer": "Mã khách hàng/CMND/Số điện thoại",
    "HomePhone": "Số điện thoại nhà",
    "HostBranch": "Chi nhánh sở hữu",
    "IC": "Số CMND / CCCD",
    "ICBackImage": "Hình mặt sau CMND / CCCD",
    "ICFrontImage": "Hình mặt trước CMND / CCCD",
    "ID": "ID",
    "Identity": "Định danh",
    "IDNo": "Số",
    "IDType": "ID Type",
    "Ignore": "Bỏ qua",
    "ImgFull": "Hình ảnh hóa đơn",
    "Import": "Import",
    "ImportedFile": "Đã import file",
    "ImportReconciliationFile": "Import File Chênh lệch",
    "Inactive": "Vô hiệu",
    "Incorrect": "Sai lệch",
    "Ineffective": "Hết hiệu lực",
    "Information": "Thông tin",
    "InputType": "Input Type",
    "Inquiry": "Tra cứu",
    "Insert": "Lưu",
    "InsertInstallment": "Tạo yêu cầu",
    "InstallmentAllow": "Cho phép trả góp",
    "InstallmentJoin": "Tham gia trả góp",
    "Installment": "Trả góp",
    "InstallmentAmount": "Số tiền trả góp",
    "InstallmentBank": "Ngân hàng trả góp",
    "InstallmentCreate": "Tạo yêu cầu trả góp",
    "InstallmentFeeBank": "Phí trả góp theo ngân hàng",
    "InstallmentFeeMerchantDetail": "Chi tiết phí đại lý",
    "InstallmentFeeMerchant": "Phí trả góp đại lý",
    "InstallmentFeeTenorAmount": "Số tiền KH chịu phí thanh toán thẻ",
    "InstallmentID": "Mã YCTG",
    "InstallmentRequest": "Yêu cầu trả góp",
    "InstallmentRequestDetail": "Chi tiết yêu cầu trả góp",
    "InstallmentRequestManagement": "Quản lý yêu cầu trả góp",
    "InstallmentRefundDebtErrorCode": "Mã lỗi công nợ hoàn phí TG",
    "InstallmentManagement": "Quản lý trả góp",
    "InstallmentStatus": "Trạng thái trả góp",
    "InstallmentView": "Xem trả góp",
    "InstallMinimum": "Số tiền trả góp tối thiểu",
    "InstBankFee": "Phí trả cho Bank",
    "InstDevUnitFee": "Phí trả góp thu của ĐVPT",
    "InstMerchantFee": "Phí trả góp thu của Merchant",
    "InternalServerError": "Lỗi hệ thống",
    "InvalidAction": "Thao tác không hợp lệ.",
    "InvalidBankCode": "Ngân hàng không hợp lệ.",
    "InvalidCurrency": "Mã tiền tệ không hợp lệ.",
    "InvalidEffectDate": "Ngày hiệu lực không hợp lệ.",
    "InvalidFileData": "Dữ liệu không hợp lệ.",
    "InvalidLocalBank": "Local Bank không hợp lệ.",
    "InvalidLocalJCB": "Local JCB không hợp lệ.",
    "InvalidLocalMaster": "Local Master không hợp lệ.",
    "InvalidLocalUnionPay": "Local UnionPay không hợp lệ.",
    "InvalidLocalVisa": "Local Visa không hợp lệ.",
    "InvalidMCC": "MCC không hợp lệ.",
    "InvalidMID": "MID không hợp lệ.",
    "InvalidMIDAndMCC": "MID và MCC không hợp lệ.",
    "InvalidNewPassword": "Mật khẩu mới không được trùng với 5 mật khẩu gần nhất, vui lòng kiểm tra lại.",
    "InvalidOldPassword": "Mật khẩu cũ không hợp lệ",
    "InvalidOtherJCB": "Other JCB không hợp lệ.",
    "InvalidOtherMaster": "Other Master không hợp lệ.",
    "InvalidOtherUnionPay": "Other Union Pay không hợp lệ.",
    "InvalidOtherVisa": "Other Visa không hợp lệ.",
    "InvalidStatus": "Trạng thái không hợp lệ",
    "InvalidTransaction": "Tổng số giao dịch không hợp lệ :",
    "InvalidTransactionToCreateInstallmentRequest": "Giao dịch không hợp lệ để tạo yêu cầu trả góp",
    "InvalidUsernameOrPassword": "Đăng nhập thất bại. Tên đăng nhập hoặc Mật khẩu không chính xác.<br/><strong>Lưu ý:</strong><br/>Mật khẩu có phân biệt giữa chữ hoa và chữ thường.",
    "InvalidVNBank": "VN Bank không hợp lệ.",
    "InvalidVNJCB": "VN JCB không hợp lệ.",
    "InvalidVNMaster": "VN Master không hợp lệ.",
    "InvalidVNUnionPay": "VN Union Pay không hợp lệ.",
    "InvalidVNVisa": "VN Visa không hợp lệ.",
    "InvoiceAddress": "Địa chỉ trên hóa đơn",
    "InvoiceNumber": "Số hóa đơn",
    "IPLogin": "IP đăng nhập",
    "IsAllowDelete": "Cho phép Xóa",
    "IsAllowExport": "Cho phép Xuất dữ liệu",
    "IsAllowInsert": "Cho phép Thêm",
    "IsAllowTopup": "Cho phép Top Up",
    "IsAllowUpdate": "Cho phép Cập nhật",
    "IsAuthenticateCustomer": "Xác thực khách hàng",
    "IsBasicCard": "Thẻ chính",
    "IsCorporateCard": "Thẻ doanh nghiệp",
    "IsCorporateCustomer": "Khách hàng / Doanh nghiệp",
    "IsDisable_ext": "Vô hiệu",
    "IsDisable_pos": "Trạng thái",
    "IsDisable": "Vô hiệu",
    "IsDisableOnInsert": "Is Disable On Insert",
    "IsDisableOnUpdate": "Is Disable On Update",
    "IsFixAmount": "Số tiền cố định",
    "IsHeadOffice": "Is Head Office",
    "IsIdentifyCustomer": "Định danh KH?",
    "IsInstallment": "Có YCTG",
    "IsInUse": "Đang sử dụng",
    "IsLazyLoad": "Is Lazy Load",
    "IsLogical": "Is Logical",
    "IsOnline": "Trực tuyến",
    "IsProgressiveFee": "Phí theo bậc thang",
    "IsRegisterInstallment": "Tham gia trả góp",
    "IsRequire": "Bắt buộc",
    "IsReversal": "Có yêu cầu hủy/đảo",
    "IsRunTime": "Is Run Time",
    "IsSettlement": "Đã kết toán",
    "IssueBank": "Ngân hàng phát hành",
    "IsUseDefaultLimit": "Sử dụng hạn mức mặc định",
    "IsUseOnce": "Sử dụng một lần",
    "IsUseReceipt": "Theo mẫu đại lý",
    "IsBlockInternationalCard":"Chặn GD đầu BIN thẻ nước ngoài",
    "Items": "Items",
    "Keyword": "Từ khóa",
    "LanguageCode": "Ngôn ngữ",
    "LastDigitsCardNumber": "Số thẻ (4 số cuối)",
    "LastEditPasswordDateTime": "Thời gian đổi mật khẩu gần nhất: {$INTERPOLATION}",
    "LastLoginDate": "Truy cập gần đây",
    "LastName": "Tên",
    "Latitude": "Vĩ độ",
    "lblIsSettlement": "Đã kết toán",
    "lblSoftPOSUser": "SoftPOS User",
    "LDAPCommonName": "LDAP Common Name",
    "LineManager": "Quản lý trực tiếp",
    "ListMerchantID": "Danh sách mã đại lý",
    "ListPolicy": "ListPolicy",
    "Load": "Tải",
    "LocalBank": "Local Bank",
    "LocalBankFix": "Local Bank Fix",
    "LocalDateTime": "Ngày giờ hệ thống",
    "LocalJCB": "Local JCB",
    "LocalJCBFix": "Local JCB Fix",
    "LocalMaster": "Local Master",
    "LocalMasterFix": "Local Master Fix",
    "LocalName": "Local Name",
    "LocalUnionPay": "Local Union Pay",
    "LocalUnionPayFix": "Local UnionPay Fix",
    "LocalVisa": "Local Visa",
    "LocalVisaFix": "Local Visa Fix",
    "Lock": "Khóa",
    "Locked": "Khóa",
    "LogAction": "Lịch sử thao tác",
    "LogDetail": "Chi tiết",
    "LogID": "Số thứ tự",
    "LogInformation": "Thông tin lịch sử",
    "LoginWith": "ĐĂNG NHẬP VỚI",
    "LogMessage": "Ghi chú",
    "Logo": "URL Logo",
    "LogoBank": "Logo",
    "LogManagement": "Quản lý log",
    "LogLoginManagement": "Quản lý log đăng nhập",
    "LogDataConfigurationManagement":"Quản lý log cấu hình dữ liệu",
    "LogFunctionalManagement": "Quản lý log chức năng",
    "Longitude": "Kinh độ",
    "MailCC": "CC",
    "MailTitle": "Tiêu đề",
    "Male": "Nam",
    "ManualPanLimit_Visa": "Hạn mức giao dịch nhập PAN",
    "ManualPANLimitJCB": "Manual PAN Limit JCB",
    "ManualPANLimitMaster": "Manual PAN Limit Master",
    "ManualPANLimitVCCS": "Manual PAN Limit VCCS",
    "ManualPANLimitVisa": "Manual PAN Limit Visa",
    "ManualSettlementDetail": "Chi Tiết Kết Toán Thủ Công",
    "ManualSettlementManagement": "Quản Lý Kết Toán Thủ Công",
    "MasterAccountNumber": "Số tài khoản gốc",
    "MaxAmount": "Số tiền tối đa",
    "MaximumLatePaymentDays": "Số ngày ân hạn",
    "MaximumPaymentDays": "Số ngày trả nợ",
    "MCC NOT EXISTS": "MCC không tồn tại",
    "MCC": "MCC",
    "MCCInfo": "Thông tin MCC",
    "MDRBank": "MDR - Ngân Hàng",
    "MDRBankAdd": "Thêm MDR - Bank",
    "MDRBankApprove": "Phê duyệt MDR - Ngân hàng",
    "MDRBankFee": "Tính Phí MDR - Ngân hàng",
    "MDRBankImport": "Import MDR - Ngân Hàng",
    "MDRBankInformation": "Chi Tiết MDR - Bank",
    "MDRBankFeeCalculateConfirmation": "Bạn có chắc muốn tính phí MDR ngân hàng",
    "MDRCode": "Mã MDR",
    "MDRDescription": "Ghi chú",
    "MDRMerchant": "MDR - Đại Lý",
    "MDRMerchantAdd": "Thêm MDR - Đại Lý",
    "MDRMerchantApprove": "Phê duyệt MDR - Đại lý",
    "MDRMerchantFee": "Tính Phí MDR - Đại Lý",
    "MDRMerchantFeeCalculateConfirmation": "Bạn có chắc muốn tính phí MDR Merchant",
    "MDRMerchantID": "MDRMerchant ID",
    "MDRMerchantImport": "Import MDR - Đại Lý",
    "MDRMerchantInformation": "Chi Tiết MDR - Đại Lý",
    "MDRPercent": "Tỉ lệ MDR",
    "MDRStatus": "Trạng thái",
    "MDRType": "Loại MDR",
    "Merchant_EstimatedPay": "Số tiền phí thu hộ dự kiến",
    "Merchant_Pay": "Số tiền phí thu hộ thực tế",
    "Merchant": "Đại lý",
    "MerchantCode": "MID",
    "MerchantDiscountRate": "Phí chiết khấu (MDR)",
    "MerchantExportByMOMerchantIDs": "Export thông tin merchant theo MO ID",
    "MerchantGUID": "Mã đại lý",
    "MerchantID": "Mã đại lý",
    "MerchantInfo": "Thông Tin Đại Lý",
    "MerchantManagement": "Quản Lý Đại Lý",
    "MerchantMDRFeeAmount": "Số tiền KH chịu phí Trả góp",
    "MerchantName": " Đại lý",
    "MerchantOwned": "Thuộc đại lý",
    "MerchantType": "Loại hình KD",
    "Message.AccessDenied": "Bạn không có quyền truy cập.",
    "Message.AccountOrCard": "Số tài khoản/Số thẻ",
    "Message.ActionFail": "{$INTERPOLATION} thất bại. {$INTERPOLATION_1}",
    "Message.ActionSuccess": "{$INTERPOLATION} thành công.",
    "Message.ActivateAccountConfirm": "Bạn có chắc muốn kích hoạt tài khoản?",
    "Message.All": "Tất cả",
    "Message.ApproveSuccess": "Yêu cầu đã được duyệt",
    "Message.AreYouSureWantToDeleteRecord": "Bạn có chắc chắn muốn xóa bản ghi?",
    "Message.AreYouSureYouWantToApprove": "Bạn có chắc bạn muốn DUYỆT yêu cầu này?",
    "Message.AreYouSureYouWantToContinue": "Bạn có chắc là bạn muốn tiếp tục?",
    "Message.AreYouSureYouWantToDELETE": "Bạn có chắc là bạn muốn XÓA?",
    "Message.BankRequired": "Trường ngân hàng không được bỏ trống",
    "Message.CardBalanceNotEnough": "Thẻ không đủ số dư",
    "Message.CardHasLinked": "Thẻ đã định danh",
    "Message.CardStatusInvalid": "Trạng thái thẻ không hợp lệ",
    "Message.ChangePasswordSuccess": "Cập nhật mật khẩu mới thành công",
    "Message.ChooseTitle": "Quyền theo chức danh",
    "Message.ChooseXLSXFile": "Vui lòng chọn file xlsx.",
    "Message.ComebackLater": "Vui lòng thử lại sau",
    "Message.ConfidenceLow": "Điểm tin cậy thấp",
    "Message.ConfirmRefundApprove": "Bạn có chắc chắn muốn duyệt hoàn giao dịch?",
    "Message.ContactAdministrator": "Vui lòng thử lại hoặc liên hệ người quản trị hệ thống",
    "Message.ContentNoAuthentication": "Bạn đang thực hiện thanh toán mà không cần xác thực. Nhấn nút <strong>XÁC NHẬN</strong> để thanh toán. Ngược lại, nhấn <strong>HỦY</strong> để thoát.",
    "Message.ContentSMS": "Mã xác thực có hiệu lực trong vòng 5 phút.<br /> Nếu quá thời gian trên, bạn có thể nhấn <b>GỬI SMS</b> để nhận mã xác thực mới.",
    "Message.ContentSoftToken": "Mã xác thực có hiệu lực trong vòng 30 giây.<br /> Nếu quá thời gian trên, vui lòng nhập lại mã xác thực gần nhất.",
    "Message.DataNotFound": "Không tìm thấy dữ liệu liên quan.",
    "Message.DeclineSuccess": "Yêu cầu đã bị từ chối",
    "Message.DeleteFail": "Vô hiệu thất bại. {$INTERPOLATION}",
    "Message.DeleteRecordFail": "Xóa bản ghi thất bại. {$INTERPOLATION}",
    "Message.DeleteRecordSuccessfully": "Xóa bản ghi thành công",
    "Message.DeleteSuccess": "Vô hiệu thành công",
    "Message.DisableAccountConfirm": "Bạn có chắc muốn vô hiệu tài khoản?",
    "Message.DisableFail": "Vô hiệu thất bại. {$INTERPOLATION}",
    "Message.DisableSuccess": "Vô hiệu thành công.",
    "Message.EmptyFile": "Nội dung trống",
    "Message.EnableFail": "Kích hoạt thất bại. {$INTERPOLATION}",
    "Message.EnableSuccess": "Kích hoạt thành công.",
    "Message.Error403": "Bạn không có quyền truy cập thông tin này",
    "Message.ExceedProductLimit": "Vượt hạn mức sản phẩm",
    "Message.Failure": "Thất bại",
    "Message.FailureAction": "Thao tác thất bại. {$INTERPOLATION}",
    "Message.FailureUploadFile": "Có lỗi trong quá trình upload file.",
    "Message.FailureTransaction": "Giao dịch thu hộ hạch toán không thành công, vui lòng kiểm tra lại.",
    "Message.FileNameAlreadyExists": "Tên file đã tồn tại trên hệ thống.",
    "Message.ForgotPassword": "Hướng dẫn đặt lại mật khẩu đã được gửi tới email của bạn.",
    "Message.FundTransferFail": "Chuyển khoản thất bại. {$INTERPOLATION}",
    "Message.FundTransferInformation": "Thông tin chuyển khoản",
    "Message.FundTransferSuccess": "Chuyển khoản thành công.",
    "Message.HeaderNoAuthentication": "Không Xác Thực",
    "Message.HeaderSMS": "Nhập mã OTP",
    "Message.HeaderSoftToken": "Nhập mã Token",
    "Message.IncorrectUsername": "Tên đăng nhập không chính xác, vui lòng kiểm tra lại.",
    "Message.InsertFail": "Thêm mới thất bại. {$INTERPOLATION}",
    "Message.InsertSuccess": "Thêm mới thành công.",
    "Message.InternalServerError": "CÓ LỖI XẢY RA!!!",
    "Message.InvalidAction": "Thao tác không hợp lệ.",
    "Message.InvalidCompareDate": "Ngày hiệu lực không hợp lệ. Ngày hiệu lực cần lớn hơn ngày tạo và ngày duyệt.",
    "Message.InvalidData": "Dữ liệu không hợp lệ",
    "Message.InvalidDate": "Ngày không hợp lệ.",
    "Message.InvalidDateOver31Days": "Khoảng thời gian tìm kiếm cần nhỏ hơn hoặc bằng 31 ngày",
    "Message.InvalidDateOver31Days.1": "Khoảng thời gian đã chọn không hợp lệ (>31 ngày), vui lòng kiểm tra lại thông tin",
    "Message.InvalidEmail": "Email không hợp lệ.",
    "Message.InvalidField": "\"{$INTERPOLATION}\" không hợp lệ.",
    "Message.InvalidFieldLength": "Độ dài \"{$INTERPOLATION}\" không phù hợp. Tối thiểu = {$INTERPOLATION_1}, Tối đa = {$INTERPOLATION_2}",
    "Message.InvalidFileData": "Định dạng file không hợp lệ. Vui lòng kiểm tra lại.",
    "Message.InvalidFileSize": "File vượt dung lượng cho phép.",
    "Message.InvalidFileType": "File không đúng dịnh dạng.",
    "Message.InvalidMax": "Giá trị tối đa là {$INTERPOLATION}",
    "Message.InvalidMaxLength": "Tối đa {$INTERPOLATION} ký tự",
    "Message.InvalidMin": "Giá trị tối thiểu là {$INTERPOLATION}",
    "Message.InvalidMinLength": "Phải có ít nhất {$INTERPOLATION} ký tự",
    "Message.InvalidMonth": "Tháng không hợp lệ",
    "Message.InvalidSelectedTime": "Khoảng thời gian đã chọn không hợp lệ, vui lòng kiểm tra lại thông tin",
    "Message.InvalidSettlementHours": "Trường giờ kết toán tự động không được bỏ trống",
    "Message.InvalidTransactionToCreateInstallment": "Giao dịch không hợp lệ để tạo yêu cầu trả góp",
    "Message.InstallmentCreateSuccess": "Tạo yêu cầu trả góp thành công.",
    "Message.InstallmentCreateFail": "Tạo yêu cầu trả góp không thành công, vui lòng kiểm tra lại",
    "Message.InvalidActionAndTryAgain": "Thao tác không thành công, vui lòng kiểm tra lại",
    "Message.LackOfFunds": "Không đủ số dư",
    "Message.LockedAccountNeedContacting": "Tài khoản này đang tạm khóa. Vui lòng liên hệ cấp quản lý để được hỗ trợ.",
    "Message.MaxDate": "Ngày được chọn phải nhỏ hơn",
    "Message.MerchantIDRequired": "Trường mã đại lý không được bỏ trống",
    "Message.MIDNotExist": "MID không hợp lệ",
    "Message.MinDate": "Ngày được chọn phải lớn hơn",
    "Message.MoveBranchDeviceError": "Thiết bị không thể chuyển qua đại lý khác, vui lòng kiểm tra lại thông tin trước khi thực hiện",
    "Message.MoveBranchTerminalError": "Bộ thông tin này đã được setup cho máy POS , vui lòng kiểm tra lại thông tin trước khi thực hiện",
    "Message.NewPasswordMatchOldPassword": "Mật khẩu mới không được trùng mật khẩu cũ, vui lòng kiểm tra lại.",
    "Message.No": "Huỷ",
    "Message.NotFoundFileData": "File upload không có dữ liệu.",
    "Message.NotFoundText": "Không tìm thấy dữ liệu liên quan",
    "Message.NotUse": "Không Sử Dụng",
    "Message.NoWhitespace": "Không được chứa toàn khoảng trắng",
    "Message.NumDateNotExceeded": "Khoảng cách các ngày không được vượt quá",
    "Message.OnlyEnterMCCOrMIDBank": "Chỉ được phép tìm kiếm theo một tiêu chí MCC hoặc MID Bank.",
    "Message.OTPGuide": "Vui lòng kiểm tra mã xác thực trên điện thoại.",
    "Message.OTPVerifyFailure": "Mã xác thực của bạn không hợp lệ",
    "Message.OTPVerifySuccess": "Mã xác thực của bạn hợp lệ",
    "Message.OverSizeFileUpload": "File upload quá dung lượng. Vui lòng kiểm tra lại.",
    "Message.PasswordManagement": "Quản lý mật khẩu",
    "Message.PasswordPolicyNotMatch": "Mật khẩu mới chưa đúng định dạng quy định, vui lòng nhập lại.",
    "Message.PaymentFail": "Thanh toán thất bại. {$INTERPOLATION}",
    "Message.PaymentSuccess": "Thanh toán thành công",
    "Message.PleaseEnterReversalPurpose": "Vui lòng nhập vào Lý do.",
    "Message.PleaseSelectFileXlsx": "Vui lòng chọn file xlsx.",
    "Message.ReasonRequired": "Trường lý do yêu cầu bắt buộc nhập",
    "Message.ReceiverInformation": "Thông tin người nhận",
    "Message.ReconciliationApproveFailure": "Có lỗi xảy ra trong quá trình duyệt, vui lòng thử lại sau.",
    "Message.ReconciliationApproveSuccess": "Gửi yêu cầu hạch toán thành công.",
    "Message.ReconciliationRejectFailure": "Từ chối duyệt file thất bại.",
    "Message.ReconciliationRejectSuccess": "Từ chối duyệt file thành công.",
    "Message.RefundResendDebtFailed": "Hạch toán không thành công.",
    "Message.RefundRevertFailed": "Revert giao dịch không thành công.",
    "Message.ReportCriteriaInformation": "Tiêu chí báo cáo",
    "Message.ReportDetailInformation": "Báo cáo chi tiết",
    "Message.ReportSummaryInformation": "Báo cáo tổng hợp",
    "Message.required": "Trường bắt buộc nhập",
    "Message.RequiredFail": "Vui lòng nhập các trường bắt buộc",
    "Message.RequireKYC": "Vui lòng hoàn tất quá trình KYC",
    "Message.ResetPasswordConfirm": "Bạn có chắc muốn thiết lập lại mật khẩu tài khoản?",
    "Message.ResetPasswordSuccess": "Hướng dẫn đặt lại mật khẩu đã được gửi tới email của bạn.",
    "Message.ReversalFail": "Hủy giao dịch thất bại. {$INTERPOLATION}",
    "Message.ReversalSuccess": "Hủy giao dịch thành công.",
    "Message.RoleInformation": "Thông tin phân quyền",
    "Message.SearchInformation": "Thông tin tra cứu",
    "Message.SectionAccountInformation": "Thông tin tài khoản",
    "Message.SectionAlternativeAddress": "Địa chỉ tạm trú",
    "Message.SectionAuthentication": "Thông tin xác thực",
    "Message.SectionBankAccountInformation": "Thông tin tài khoản ngân hàng",
    "Message.SectionBusinessInformation": "THÔNG TIN KINH DOANH",
    "Message.SectionCardDetail": "Thông tin thẻ",
    "Message.SectionCardHistory": "Lịch sử thẻ",
    "Message.SectionCardInquiry": "Tra cứu thông tin thẻ",
    "Message.SectionCardPayment": "Thông tin thanh toán",
    "Message.SectionCardTopup": "Thông tin nạp tiền",
    "Message.SectionCompanyAddress": "Địa chỉ công ty",
    "Message.SectionContactInformation": "Thông tin liên hệ",
    "Message.SectionCustomerInformation": "Thông tin khách hàng",
    "Message.SectionPersonalInformation": "Thông tin cá nhân",
    "Message.SectionPrimaryAddress": "Địa chỉ thường trú",
    "Message.SectionProcessInformation": "Quá trình xử lí chuyển khoản",
    "Message.SectionRegistrationInformation": "THÔNG TIN ĐĂNG KÍ DỊCH VỤ",
    "Message.SectionRequestInformation": "Thông tin yêu cầu",
    "Message.SectionReversalInformation": "Thông tin hủy giao dịch",
    "Message.SectionTransactionHistory": "Lịch sử giao dịch",
    "Message.SectionTransactionInformation": "Thông tin giao dịch",
    "Message.SenderInformation": "Thông tin người gửi",
    "Message.SendRequestApproveSuccess": "Gửi duyệt file chênh lệch thành công.",
    "Message.SendRequestFailure": "Gửi yêu cầu thất bại",
    "Message.SendRequestSuccess": "Gửi yêu cầu thành công",
    "Message.ServerError": "Lỗi hệ thống.",
    "Message.StatusChanged": "Thông tin đã được cập nhật bởi người khác.",
    "Message.SuccessAction": "Thao tác thành công.",
    "Message.Successful": "Thành công",
    "Message.SystemErrorReTry": "Hệ thống bị gián đoạn. Vui lòng thử lại.",
    "Message.TabBranch": "Thông tin Chi Nhánh",
    "Message.TabDevice": "Thông tin Thiết bị",
    "Message.TabDeviceInfo": "Thông tin Account App",
    "Message.TabEReceiptInfo": "Thông tin hóa đơn",
    "Message.TabGeneral": "Thông tin cơ bản",
    "Message.TabHistory": "Thông tin lịch sử",
    "Message.TabImage": "Thông tin hình ảnh",
    "Message.TabMCC": "Thông tin MCC",
    "Message.TabMerchant": "Thông tin Đại lý",
    "Message.TabRefund": "Thông tin Hoàn tiền",
    "Message.TabSetting": "Thông tin Cấu hình",
    "Message.TabTerminal": "Thông tin MID & TID",
    "Message.TheSystemOnlySupportsXlsxFileFormat": "Hệ thống chỉ hỗ trợ định dạng file xlsx.",
    "Message.TokenInvalid": "Token không hợp lệ",
    "Message.TopupFail": "Nạp tiền thất bại. {$INTERPOLATION}",
    "Message.TopupSuccess": "Nạp tiền thành công.",
    "Message.Total": "Tổng số",
    "Message.TransactionNotExist": "Giao dịch không tồn tại.",
    "Message.TransactionNotSettledReCheck": "Giao dịch thu hộ chưa hạch toán, vui lòng kiểm tra lại.",
    "Message.UnderMaintenance": "Hệ thống đang bảo trì",
    "Message.UpdateFail": "Cập nhật thất bại. {$INTERPOLATION}",
    "Message.UpdateMDRMerchantConfirm": "Bạn có chắc muốn cập nhật thông tin MDR Đại lý",
    "Message.UpdatePasswordNotificationUser": "Hướng dẫn đặt lại mật khẩu đã được gửi tới email của người dùng.",
    "Message.UpdatePasswordSuccess": "Cập nhật mật khẩu mới thành công",
    "Message.UpdateReconciliationSuccess": "Cập nhật trạng thái file đối soát thành công",
    "Message.UpdateRefundFailure": "Thao tác không thành công, do Hoàn tiền đã được chuyển sang trạng thái khác! Vui lòng kiếm tra lại.",
    "Message.UpdateSuccess": "Cập nhật thành công.",
    "Message.UploadFileSuccess": "Upload file thành công.",
    "Message.WaitForApproval": "Yêu cầu của bạn đang được xem xét. Vui lòng quay lại sau.",
    "Message.Yes": "Đồng ý",
    "MID AND BANKCODE NOT EXISTS": "Cặp MID-BankCode không tồn tại",
    "MID": "MID",
    "MIDBank": "MID Bank",
    "MIDNotBelongsToBank": "MID không có khai báo theo Ngân hàng",
    "MIDOrTID": "MID/TID",
    "MinimumAmount": "Số tiền trả góp tối thiểu",
    "MinLength": "Min Length",
    "MistakenAccountingNo": "Số hạch toán sai lệch",
    "MMC": "MMC",
    "MMDR_FeeDifference": "Chênh lệch phí đại lý",
    "MMDR_Fix": "Mức phí cố định thực tế",
    "MMDR_Lastupdated": "Ngày tính phí",
    "MMDR_percent": "Mức phí % thực tế",
    "MMDR_TempFix": "Mức phí cố định dự kiến",
    "MMDR_TempPercent": "Mức phí % dự kiến",
    "Mobile": "Số di động",
    "Model_pos": "Chủng loại thiết bị",
    "Model": "Mẫu số",
    "ModelBill": "Thông tin mẫu hóa đơn",
    "MOFeeID": "Mã biểu phí MO",
    "MOMerchantID": "Mã đại lý MO",
    "Month": "Tháng",
    "MonthExpire": "Thời hạn (Tháng)",
    "MOShopID": "Mã chi nhánh MO",
    "MOTerminalID": "Mã điểm bán MO",
    "Nationality": "Quốc tịch",
    "NeedMore": "Chờ bổ sung",
    "New": "Mới",
    "NewPassword": "Mật khẩu mới",
    "NewValue": "Giá trị mới",
    "No": "Không",
    "NodeRootOfMerchant": "Chi nhánh gốc ĐL",
    "NotAction": "Không hoạt động",
    "Note": "Ghi chú",
    "Notice": "Lưu ý:",
    "NotAuthenticated":"User chưa được phân quyền truy cập. Vui lòng liên hệ Quản trị viên để được phân quyền.",
    "NumberCancel": "Số lượng yêu cầu hủy",
    "NumberNew": "Số lượng yêu cầu trả góp",
    "NumberOfTxn": "Số lượng giao dịch",
    "NumberOfTxnSuccess": "SLGD TC",
    "NumberOfTxnUnsuccess": "SLGD KTC",
    "NumberProgressiveFee": "Số bậc",
    "NumberTxnDebt": "Tổng Số GD Công Nợ",
    "NumberTxnSpos": "Tổng Số GD SOFTPOS",
    "NumOfTransactionPoint": "Số điểm giao dịch",
    "ObjectID": "ID đối tượng",
    "ObjectType": "Module",
    "OffchainCode": "Mã đối soát",
    "OK": "Đồng ý",
    "OldPassword": "Mật khẩu cũ",
    "OldValue": "Giá trị cũ",
    "OnClientChange": "On Client Change",
    "OpeningAmount": "Số tiền ban đầu",
    "OpeningBalanceAmount": "Tiền ban đầu",
    "OrderNumber": "STT",
    "ORGTransID": "Mã GD gốc",
    "OriginalTransactionCreatedDate": "Thời gian giao dịch",
    "OriginalTransactionID": "Mã giao dịch gốc",
    "OtherBank": "Other Bank",
    "OtherInformation": "Thông tin khác",
    "OtherJCB": "Other JCB",
    "OtherJCBFix": "Other JCB Fix",
    "OtherMaster": "Other Master",
    "OtherMasterFix": "Other Master Fix",
    "OtherReversalPurpose": "Lý do khác",
    "Others": "Others",
    "OtherUnionPay": "Other Union Pay",
    "OtherUnionPayFix": "Other Union Pay Fix",
    "OtherVisa": "Other Visa",
    "OtherVisaFix": "Other Visa Fix",
    "Overwrite": "Ghi đè dữ liệu",
    "Page": "Trang",
    "ParentID": "Trực thuộc chi nhánh",
    "ParentIDOrDevelopmentUnit": "Trực thuộc chi nhánh / Đơn vị phát triển",
    "ParentOfMerchant": "Đơn vị phát triển",
    "PartialRefund": "Hoàn một phần",
    "PartnerID": "Mã cộng sự",
    "PartnerUserID": "Mã người dùng",
    "Password": "Mật khẩu",
    "PasswordChangeNotice": "Mật khẩu gồm 8-64 kí tự, trong đó bao gồm ít nhất 1 số, 1 chữ, 1 kí tự đặc biệt và không được trùng với 5 mật khẩu gần nhất",
    "Payment": "Thanh toán",
    "PaymentBank": "Ngân hàng thanh toán",
    "PaymentDate": "Ngày thanh toán",
    "PaymentSettled": "Đã thanh quyết toán",
    "PaymentTypeAccount": "Hình thức thanh toán",
    "PaymentTypeAccounting": "Thanh toán",
    "PaymentWalletNumber": "Số ví điện tử",
    "PercentAmount": "Phần trăm",
    "PermissionLog": "Lịch Sử Cập Nhật Quyền",
    "PhoneExtension": "Số nội bộ",
    "PhoneNumber": "Số điện thoại",
    "PINNumber": "PIN",
    "PlaceHolder.Customer": "Mã khách hàng/CMND/Số điện thoại",
    "PlaceHolder": "Place Holder",
    "PlaceIssue": "Nơi cấp",
    "PlaceOfProduction": "Nơi sản xuất",
    "PointID": "Mã điểm thưởng",
    "PointName": "Tên gợi nhớ",
    "PopupReconciliationReject": "Lý do từ chối duyệt",
    "PortraitImage": "Hình khách hàng",
    "POSAmount": "Số lượng POS",
    "POSEntryMode": "Hình thức giao dịch",
    "POSID": "Điểm bán",
    "POSInformation": "Thông Tin Điểm bán",
    "POSManagement": "Quản Lý Điểm bán",
    "POSName": "Tên điểm bán",
    "POSOrSID": "Điểm bán/thu",
    "PostalCode": "Mã bưu chính",
    "PrefixName": "Tên tiền tố",
    "PrimaryKey": "Primary Key",
    "PrimaryPhoneNumber": "Số điện thoại",
    "Print": "In",
    "Procedure": "Thủ tục",
    "Process": "Xử lý",
    "Processed": "Đã xử lý",
    "Processing": "Đang xử lý",
    "ProcessReason": "Lý do Duyệt/Từ chối/Hủy",
    "ProcessResult": "Kết quả xử lý",
    "ProcessStatusOfReconciliationIsNotTimeOut": "Trạng thái xử lý không hợp lệ",
    "ProcessType": "Nghiệp vụ xử lý",
    "Product": "Sản phẩm",
    "ProductApply": "Sản phẩm áp dụng",
    "ProductBIN": "Mã BIN thẻ",
    "ProductCode": "Mã sản phẩm",
    "ProductID": "Số thứ tự",
    "ProductImage": "Ảnh sản phẩm",
    "ProductLimit": "Hạn mức sản phẩm",
    "ProductName": "Tên sản phẩm",
    "ProductSchemeCode": "Cá nhân / Doanh nghiệp",
    "ProductType": "Loại sản phẩm",
    "ProductTypeCode": "Loại sản phẩm",
    "Progressive": "Bậc thang",
    "ProgressiveBy": "Theo tiêu chí",
    "Promotion": "Khuyến mãi",
    "PromotionDescription": "Mô tả",
    "PromotionID": "Mã khuyến mãi",
    "PromotionName": "Tên khuyến mãi",
    "PwdRuleChar": "Tối thiểu 1 kí tự chữ",
    "PwdRuleLength8And64": "Từ 8 đến 64 ký tự",
    "PwdRuleNum": "Tối thiểu 1 kí tự số",
    "PwdRuleSpec": "Tối thiểu 1 ký tự đặc biệt. Ví dụ: !@#$%^*()”",
    "QRCode": "Mã QR",
    "Quantity": "Số lượng",
    "Race": "Dân tộc",
    "Range": "Bậc",
    "Re-Enroll": "Re-Enroll",
    "ReceiptEmailEmpty": "Không có thông tin email, đề nghị click vào link hình ảnh hóa đơn để tải về.",
    "ReceiptFullImageEmpty": "Giao dịch không có dữ liệu hình ảnh hóa đơn. Vui lòng liên hệ quản trị viên.",
    "ReceiptID": "Mã hóa đơn",
    "ReceiptType": "Loại hóa đơn",
    "Receive": "Tiếp nhận",
    "ReceiveAmount": "Số tiền nhận được",
    "Receiver": "Người tiếp nhận",
    "ReceiverAccount": "Tài khoản nhận",
    "RecNo": "STT",
    "Reconciliation": "Đối Soát Công Nợ",
    "ReconciliationCode": "Mã đối soát",
    "ReconciliationDate": "Thời Gian Đối Soát",
    "ReconciliationDateFrom": "Ngày đối soát từ",
    "ReconciliationDateTo": "Ngày đối soát đến",
    "ReconciliationDetail": "Chi Tiết Đối Soát",
    "ReconciliationDifferenceList": "Danh Sách File Upload Chênh Lệch",
    "ReconciliationFile": "File đối soát",
    "ReconciliationID.1": "Mã đối soát",
    "ReconciliationID": "Mã hạch toán công nợ",
    "ReconciliationManagement": "Quản Lý Đối Soát",
    "ReconciliationProcess.1": "Hạch toán bổ sung",
    "ReconciliationProcess.2": "Revert giao dịch",
    "ReconciliationProcess.3": "Không xử lý",
    "ReconciliationProcess.4": "Xử lý tại hệ thống công nợ",
    "ReconciliationResult.1": "Khớp đúng",
    "ReconciliationResult.2": "SOFTPOS có, CN không",
    "ReconciliationResult.3": "SOFTPOS không, CN có",
    "ReconciliationResult.4": "Sai lệch số tiền",
    "ReconciliationResult.5": "Sai lệch số tài khoản",
    "ReconciliationResult": "Kết quả đối soát",
    "ReconciliationStatus": "Trạng thái đối soát",
    "ReconciliationTransactionDetail": "Chi tiết giao dịch đối soát",
    "ReconciliationWillBeUpdateNextTime": "File đối soát sẽ được cập nhật lại ở phiên tiếp theo",
    "Reference": "Reference",
    "ReferenceNumber": "Mã tham chiếu",
    "Refresh": "Làm mới",
    "Refund": "Hoàn Tiền",
    "RefundAmount": "Số tiền hoàn",
    "RefundAvailable": "Số tiền còn lại",
    "RefundDateTime": "Ngày giờ tạo hoàn",
    "RefundDateTimeCreate": "Ngày giờ tạo hoàn",
    "RefundDescription": "Nội dung hoàn",
    "RefundDetail": "Chi Tiết Hoàn Tiền",
    "RefundID": "Mã giao dịch hoàn",
    "RefundManagement": "Quản Lý Hoàn Tiền",
    "RefundPurpose": "Mục đích hoàn",
    "RefundStatus": "Trạng thái hoàn",
    "RefundStatusApprove": "Trạng thái duyệt hoàn",
    "RefundType": "Kiểu hoàn tiền",
    "RefundTypeAccounting": "Hoàn trả",
    "RefuseTypeAccounting": "Từ chối hoàn",
    "RegID": "Mã đăng kí",
    "RegInfo": "Chi tiết yêu cầu",
    "RegionID": "Region ID",
    "Register": "Đăng ký",
    "RegisterNew": "Đăng ký mới",
    "RegisterNewMerchant": "Tạo đại lý mới/Chi nhánh tổng",
    "RegT2PUserID": "Mã User App",
    "Reject": "Từ chối",
    "RejectAll": "Từ chối tất cả",
    "RejectDescription": "Nội dung từ chối",
    "Religion": "Tôn giáo",
    "Reload": "Tải lại",
    "ReloadAll": "Tải lại tất cả",
    "ReloadCache": "Tải lại cache",
    "Remark": "Ghi chú",
    "Remove": "Xóa",
    "RemoveCache": "Xóa cache",
    "RemoveTerminalFail": "Gỡ MID/TID thất bại",
    "RemoveTerminalSuccessful": "Gỡ MID/TID thành công",
    "Report.CardStatusDetail": "Báo cáo tình trạng thẻ (Chi tiết)",
    "Report.CardStatusSummary": "Báo cáo tình trạng thẻ (Tổng hợp)",
    "Report": "Báo cáo",
    "ReportAccumulatedBranch": "Báo cáo Chi nhánh lũy kế",
    "ReportAccumulatedMerchant": "Báo cáo Đại lý lũy kế",
    "ReportAccumulatedPos": "Báo cáo POS (điểm bán) lũy kế",
    "ReportAccumulatedTid": "Báo cáo TID lũy kế",
    "ReportBankRefundTransaction": "Báo cáo giao dịch hoàn tiền Bank",
    "ReportClosedBranchInPeriod": "Báo cáo Chi nhánh đóng trong kỳ",
    "ReportClosedMerchantInPeriod": "Báo cáo Đại lý đóng trong kỳ",
    "ReportClosedPosInPeriod": "Báo cáo POS (điểm bán) đóng trong kỳ",
    "ReportClosedTidInPeriod": "Báo cáo TID đóng trong kỳ",
    "ReportErrorStatisticThroughBank": "Thống kê lỗi qua ngân hàng",
    "ReportErrorStatisticThroughDebt": "Thống kê lỗi qua công nợ",
    "ReportGeneral": "Báo cáo tổng hợp",
    "ReportGroup": "Nhóm báo cáo",
    "ReportInitiatedRefundTransactionReport": "Báo cáo giao dịch khởi tạo hoàn",
    "ReportList": "Danh sách báo cáo",
    "ReportMidsWithFeeAdjustment": "Báo cáo các MID có điều chỉnh phí",
    "ReportMidsWithoutFeeDeclaration": "Báo cáo các MID chưa khai báo phí",
    "ReportNewlyOpenedBranchInPeriod": "Báo cáo Chi nhánh mở mới trong kỳ",
    "ReportNewlyOpenedMerchantInPeriod": "Báo cáo Đại lý mở mới trong kỳ",
    "ReportNewlyOpenedPosInPeriod": "Báo cáo POS (điểm bán) mở mới trong kỳ",
    "ReportNewlyOpenedTidInPeriod": "Báo cáo TID mở mới trong kỳ",
    "ReportOnline": "Báo cáo online",
    "ReportOperation": "Báo cáo hoạt động",
    "ReportRefund": "Báo cáo hoàn tiền",
    "ReportRefundTransaction": "Báo cáo giao dịch hoàn tiền",
    "ReportRejectRefundTransaction": "Báo cáo giao dịch từ chối hoàn",
    "ReportSuccessfulTransaction": "Giao dịch thành công",
    "ReportTransactionInformation": "Thông tin giao dịch",
    "Representative": "Người đại diện",
    "Reprint": "In lại",
    "Request": "Yêu cầu",
    "RequestBankDeny": "Yêu cầu hủy",
    "RequestCancel": "yêu cầu hủy",
    "RequestDateTime": "Thời gian yêu cầu",
    "RequestID": "Mã yêu cầu",
    "RequestIDRoot": "Mã yêu cầu gốc",
    "RequestType": "Loại yêu cầu",
    "Resend": "Gửi lại",
    "ResendDebt": "Hạch toán lại",
    "ResendDebtConfirm": "Bạn chắc chắn muốn hạch toán lại giao dịch",
    "Reset": "Làm mới",
    "ResetData": "Reset",
    "ResetPassword": "Reset mật khẩu",
    "ResetPasswordTitle": "LẤY LẠI MẬT KHẨU",
    "ResponseCode": "Mã lỗi",
    "ResponseDateTime": "Thời gian xử lý",
    "ResponseDescription": "Ghi chú",
    "Restore": "Phục hồi",
    "Result": "Kết quả",
    "Return": "Trả lại",
    "ReturnHomePage": "Quay lại trang chủ",
    "ReturnToLoginPage": "Quay lại trang chủ",
    "Revenue": "Doanh thu",
    "Reversal": "Hủy thanh toán",
    "ReversalDateTime": "Ngày Hủy/Đảo",
    "ReversalDescription": "Diễn giải",
    "ReversalMemo": "Loại GD hủy/đảo",
    "ReversalNote": "Ghi chú",
    "ReversalPurpose": "Lý do",
    "ReversalReferenceNumber": "Mã tham chiếu GD hủy/đảo",
    "ReversalSequenceNumber": "Mã số giao dịch",
    "ReversalStatus": "Trạng thái GD hủy/đảo",
    "ReversalUserID": "Người thực hiện",
    "Revert": "Chuyển tiếp",
    "ReversalDatetime": "Ngày Hủy/Đảo",
    "RewardPoint": "Điểm thưởng",
    "Role": "Tên Quyền",
    "RoleID": "Role ID",
    "RootTransactionAmount": "Số tiền gốc",
    "RowPerPage": "Số dòng",
    "SafeLimit": "Hạn mức an toàn",
    "SaleRequestID": "Mã giao dịch sale",
    "SaleStatus": "Trạng thái GD sale",
    "Save": "Lưu",
    "SchemaName": "Schema Name",
    "Search": "Tìm kiếm",
    "SecretKey": "Mã bí mật",
    "Send": "Gửi",
    "SendDate": "Ngày gửi",
    "SendEmail": "Gửi Email",
    "Sender": "Người gửi",
    "SendRequest": "Gửi yêu cầu",
    "SendRequestApprove": "Gửi Duyệt",
    "SendSMS": "Gửi SMS",
    "SendToSPOS": "Gửi SPOS",
    "SequenceNumber": "Mã số giao dịch",
    "SerialNumber": "Mã thiết bị",
    "ServiceName": "Service Name",
    "Services": "Dịch Vụ",
    "SetPasswordTitle": "ĐẶT LẠI MẬT KHẨU",
    "Setting": "Cấu hình",
    "Settle": "Kết toán",
    "SettleConfirm": "Bạn có chắc muốn quyết toán POS",
    "SettleFail": "Điểm bán kết toán lỗi",
    "SettlementBill": "Hóa đơn kết toán",
    "SettlementDate": "Ngày thanh toán",
    "SettlementDateTime": "Ngày giờ kết toán",
    "SettlementTime": "Giờ thanh toán",
    "SettlementTimeList": "Giờ kết toán tự động",
    "SettlementTransaction": "Hóa đơn SETTLEMENT",
    "SettlementVoidSale": "Hóa đơn VOID/SALE",
    "ShortName": "Tên viết tắt",
    "SignatureImage": "Hình chữ ký",
    "SignIn": "Đăng nhập",
    "SignInTitle": "ĐĂNG NHẬP",
    "SignInUp": "Đăng Nhập",
    "SignOut": "Đăng xuất",
    "SoftPOSUser": "User App",
    "SoftwareInfo": "Thông tin phần mềm",
    "Sort": "Sắp xếp",
    "SourceAccount": "Tài khoản nguồn",
    "SourceAccountNumber": "Tài khoản nguồn",
    "SourceBankCode": "Ngân hàng gửi",
    "SourceBranchCode": "Chi nhánh chuyển",
    "SourceData": "Nguồn dữ liệu",
    "StartDateTime": "Thời gian bắt đầu",
    "StaffID": "Mã nhân viên",
    "State": "Quận/ Huyện",
    "StateCode": "Thành phố/Tỉnh",
    "StateName": "Khu vực",
    "STATUS.APPROVED": "Đã duyệt",
    "STATUS.FOR_APPROVAL": "Chờ duyệt",
    "STATUS.REJECTED": "Từ chối",
    "Status": "Trạng thái",
    "StatusDate": "Ngày trạng thái",
    "Step_From": "Từ",
    "Step_To": "Đến",
    "SubBranchData": "Dữ liệu chi nhánh thẻ",
    "Subject": "Tiêu đề",
    "Submit": "Gửi",
    "Success": "Thành công",
    "SuccessReconcile": "Đã đối soát xong",
    "Suggestion": "Suggestion",
    "SummaryType": "Xem dạng tổng hợp",
    "Sync": "Đồng bộ",
    "System": "Hệ thống",
    "SystemNote": "Ghi chú hệ thống",
    "T2PAmount": "Số lượng user App",
    "Tab.AccountLinkedInformation": "Tài khoản liên kết",
    "Tab.PersonalInbox": "Hộp thư cá nhân",
    "Tab.UserAccountInformation": "Tài khoản người dùng",
    "Tab.UserRequestInformation": "Phiếu yêu cầu",
    "TableCacheID": "Table Cache ID",
    "TableID": "Table ID",
    "TableName": "Table Name",
    "TablePermissionID": "Table Permission ID",
    "TabMCC": "Thông tin MCC",
    "TaxNumber": "Mã số thuế/Số ĐKKD",
    "TC": "TC",
    "TemplateType": "Mẫu hóa đơn",
    "Tenor": "Kỳ hạn",
    "Tenor12": "Kỳ 12 tháng",
    "Tenor3": "Kỳ 3 tháng",
    "Tenor6": "Kỳ 6 tháng",
    "Tenor9": "Kỳ 9 tháng",
    "Tenor18": "Kỳ 18 tháng",
    "Tenor24": "Kỳ 24 tháng",
    "Tenor36": "Kỳ 36 tháng",
    "Terminal": "MID & TID",
    "TerminalCode": "TID",
    "TerminalInformation": "Thông Tin TID Và MID",
    "TerminalManagement": "Quản Lý TID Và MID",
    "TID": "TID",
    "TIDAmount": "Số lượng TID",
    "Title": "Title",
    "TitleApproveRefund": "Kết quả duyệt hoàn ({$INTERPOLATION} giao dịch)",
    "TitleDeclineRefund": "Kết quả từ chối hoàn ({$INTERPOLATION} giao dịch)",
    "ToAddress": "Người nhận",
    "TokenCode": "Mã kiểm tra",
    "Tooltip": "Tooltip",
    "TopUp": "Nạp tiền",
    "TopupLimit": "Hạn mức Top Up",
    "TopupTimes": "Số lần Top Up",
    "TotalAmount": "Số tiền chưa kết toán",
    "TotalCaches": "Tổng cache",
    "TotalTransaction": "Tổng số giao dịch :",
    "TotalTransactions": "Tổng số lượng giao dịch",
    "TotalTxn_Settle": "Tổng số lượng giao dịch kết toán",
    "TotalTxnAmount_Settle": "Tổng giá trị giao dịch kết toán",
    "TotalUnSettlementAmount": "Tổng số tiền chưa kết toán",
    "Transaction": "Giao dịch",
    "TransactionAmount": "Số tiền giao dịch",
    "TransactionDate": "Ngày giao dịch",
    "TransactionDateTime": "Ngày giờ giao dịch",
    "TransactionDescription": "Diễn giải",
    "TransactionFee": "Phí giao dịch",
    "TransactionID": "Mã giao dịch",
    "TransactionKeyword": "Từ khóa giao dịch",
    "TransactionLatitude": "Vĩ độ",
    "TransactionLimit": "Hạn mức giao dịch",
    "TransactionLimitMonth": "Hạn mức giao dịch/tháng",
    "TransactionLongitude": "Kinh độ",
    "TransactionMemo": "Ghi chú hệ thống",
    "TransactionNote": "Ghi chú",
    "TransactionPurpose": "Mục đích giao dịch",
    "TransactionResult": "Kết quả giao dịch",
    "TransactionRootID": "Mã GD gốc",
    "TransactionStatus": "Trạng thái GD sale",
    "TransactionTime": "Thời gian giao dịch",
    "TransactionTotalAmount": "Tổng số tiền",
    "TransactionTypeCode": "Loại giao dịch",
    "TransactionUserID": "Người thực hiện",
    "TransactionBlockingInformation":"Thông tin chặn giao dịch",
    "Transfer": "Chuyển khoản",
    "TransferTo": "Chuyển đến",
    "TransID": "Mã GD đối soát",
    "TypeOfAccounting": "Loại hạch toán",
    "UnEnroll": "UnEnroll",
    "Unlock": "Mở khóa",
    "UnSettlementAmount": "Số tiền chưa kết toán",
    "UnSettlementTime": "Thời gian chưa kết toán",
    "Unsuccessful": "Không thành công",
    "UPDATE_FEE": "Cập nhật phí",
    "Update": "Cập nhật",
    "UpdateDetails": "Chi tiết cập nhật",
    "UpdateAccountConfirm": "Bạn có chắc muốn cập nhật thông tin tài khoản?",
    "UpdateFee": "Cập nhật phí",
    "UpdateProcessResult": "Cập nhật kết quả xử lý",
    "UpdateProcessResultReconciliation": "Cập nhật kết quả xử lý hạch toán",
    "UpdateReconciliationFile": "Cập nhật xử lý chênh lệch",
    "UpdateRefundStatus": "Cập nhật trạng thái",
    "UpdateRequest": "Yêu cầu Sửa",
    "UpdateReconciliationStatus": "Cập nhật trạng thái đối soát",
    "Upload": "Tải lên",
    "UploadDate": "Ngày Upload",
    "User": "Người Dùng",
    "UserField01_pos": "Sim thiết bị",
    "UserField01": "Dữ liệu 01",
    "UserField02": "Dữ liệu 02",
    "UserField03": "Dữ liệu 03",
    "UserField04": "Dữ liệu 04",
    "UserField05": "Dữ liệu 05",
    "UserID": "Mã người dùng",
    "UserIDApprove": "Người duyệt/từ chối/hủy",
    "UserIDCreate": "Người tạo",
    "UserIDGenerate": "Người tạo file",
    "UserIDModify": "Người cập nhật",
    "UserIDProcess": "Người xử lý",
    "UserIDSend": "Người gửi file",
    "UserInformation": "Thông Tin Người Dùng",
    "UserIsDisabled": "User đã bị vô hiệu, vui lòng liên hệ Quản trị viên để thực hiện kích hoạt lại.",
    "UserIsLocked": "User đã bị khóa, vui lòng liên hệ Quản trị viên để xử lý.",
    "UserManagement": "Quản Lý Người Dùng",
    "UserName": "Tên đăng nhập",
    "UsernameInUsed": "Tên đăng nhập đã được sử dụng",
    "UsernameUpdate": "Điều chỉnh User",
    "UserT2P": "Tên user App",
    "UserTerminalID": "User Terminal ID",
    "UserUpload": "User thực hiện",
    "UseT2P": "Dùng cho User App",
    "Validate": "Kiểm tra",
    "Validation.Existed": "{$INTERPOLATION} đã tồn tại",
    "Validation.RequireField": "Vui lòng nhập vào {$INTERPOLATION}",
    "ValidTransaction": "Tổng số giao dịch hợp lệ :",
    "ValueOfTxn": "Giá trị giao dịch",
    "ValueOfTxnSettlement": "GTGD KT",
    "ValueOfTxnSuccess": "GTGD TC",
    "ValueOfTxnUnsuccess": "GTGD KTC",
    "Verify": "Kiểm tra",
    "View": "Xem",
    "ViewDetail": "Xem chi tiết",
    "ViewHistory": "Xem lịch sử",
    "VisibleAdminOnly": "Chỉ hiển thị với người quản trị",
    "VNBank": "VN Bank",
    "VNBankFix": "VN Bank Fix",
    "VNJCB": "VN JCB",
    "VNJCBFix": "VN JCB Fix",
    "VNMaster": "VN Master",
    "VNMasterFix": "VN Master Fix",
    "VNPAY_Pay": "Số tiền phí trả NH",
    "VNPAYQR": "VNPAY QR",
    "VNPAYSSO": "VNPAY SSO",
    "VNPAYWallet": "Ví VNPAY",
    "VNUnionPay": "VN Union Pay",
    "VNUnionPayFix": "VN Union Pay Fix",
    "VNVisa": "VN Visa",
    "VNVisaFix": "VN Visa Fix",
    "VoidDateTime": "Ngày giờ hủy",
    "VoidRequest": "Yêu cầu hủy",
    "VoidStatus": "Trạng thái GD Void",
    "WaitingForAction": "Chờ hoạt động",
    "WalletAcc": "Số ví VNPAY",
    "Ward": "Phường/Xã",
    "Wards": "Phường/Xã",
    "Website": "Trang mạng",
    "WillEffective": "Sắp hiệu lực",
    "InvalidEmailVNP": "Email không hợp lệ. Chỉ cho phép @vnpay.vn",
    "RoleCode": "Mã nhóm quyền",
    "Yes": "Có",
    "ZZ": "Dòng kết thúc của bộ từ điển. Không thêm bất kì dòng nào từ sau dòng này. Sắp xếp key tăng dần trước khi commit"
  }
}
